@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

/* .headerImg { */
/* background-image: url('./assets/images/bannerImg01.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100vw;
    height: 200px;
    overflow: hidden; */
/* } */
@layer base {
    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    body {
        @apply bg-slate-100;
    }
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
    @apply text-slate-700;
}
